export const AgeOptionList = [
  {
    label: '만 19세 미만',
    value: '만 19세 미만',
    next: 'way',
  },
  {
    label: '만 19세 이상 ~ 만 75세 미만',
    value: '만 19세 이상 ~ 만 75세 미만',
    next: 'job',
  },
  {
    label: '75세 이상',
    value: '75세 이상',
    next: 'way',
  },
]
export const JobOptionList = [
  {
    label: '고등학생',
    value: '고등학생',
    next: 'grade',
  },
  {
    label: '검정고시 준비중',
    value: '검정고시 준비중',
    next: 'final',
  },
  {
    label: '대학(원)생 [졸업 예정]',
    value: '대학(원)생 [졸업 예정]',
    next: 'school',
  },
  {
    label: '실업자 / 구직자',
    value: '실업자 / 구직자',
    next: 'final',
  },
  {
    label: '직장인',
    value: '직장인',
    next: 'companyScale',
  },
  {
    label: '자영업자 / 사업자',
    value: '자영업자 / 사업자',
    next: 'businessPeriod',
  },
  {
    label: '특수형태근로종사자 / 프리랜서',
    value: '특수형태근로종사자 / 프리랜서',
    next: 'freeMonthlyIncome',
  },
  {
    label: '비영리단체 대표',
    value: '비영리단체 대표',
    next: 'monthlyIncome',
  },
]

export const GradeOptionList = [
  {
    label: '고등학교 2학년 이하',
    value: '고등학교 2학년 이하',
    next: 'way',
  },
  {
    label: '고등학교 3학년',
    value: '고등학교 3학년',
    next: 'final',
  },
]

export const SchoolOptionList = [
  {
    label: '방송통신대학 및 사이버 대학 등 원격대학',
    value: '방송통신대학 및 사이버 대학 등 원격대학',
    next: 'final',
  },
  {
    label: '2년제 대학교',
    value: '2년제 대학교',
    next: 'final',
  },
  {
    label: '3년제 대학교',
    value: '3년제 대학교',
    next: 'univGrade',
  },
  {
    label: '4년제 대학교',
    value: '4년제 대학교',
    next: 'fourYearUnivGrade',
  },
]
export const univGradeOptionList = [
  {
    label: '1학년 2학기 이전',
    value: '1학년 2학기 이전',
    next: 'way',
  },
  {
    label: '1학년 2학기 [종료 예정]',
    value: '1학년 2학기 [종료 예정]',
    next: 'final',
  },
]
export const fourYearUnivOptionList = [
  {
    label: '2학년 2학기 이전',
    value: '2학년 2학기 이전',
    next: 'way',
  },
  {
    label: '2학년 2학기 [종료 예정]',
    value: '2학년 2학기 [종료 예정]',
    next: 'final',
  },
]

export const CompanyScaleOptionList = [
  {
    label: '대기업 종사자',
    value: '대기업 종사자',
    next: 'largeCompanyAge',
  },
  {
    label: '중소/중견 기업 종사자',
    value: '중소/중견 기업 종사자',
    next: 'final',
  },
]
export const largeCompanyAgeOptionList = [
  {
    label: '만 45세 미만',
    value: '만 45세 미만',
    next: 'way',
  },
  {
    label: '만 45세 이상~',
    value: '만 45세 이상~',
    next: 'monthlyIncome',
  },
]
export const businessPeriodOptionList = [
  {
    label: '사업 기간 1년 미만',
    value: '사업 기간 1년 미만',
    next: 'way',
  },
  {
    label: '사업 기간 1년 이상',
    value: '사업 기간 1년 이상',
    next: 'sales',
  },
]
export const freeMonthlyIncomeOptionList = [
  {
    label: '월 소득 500만원 이상',
    value: '월 소득 500만원 이상',
    next: 'way',
  },
  {
    label: '월 소득 500만원 미만',
    value: '월 소득 500만원 미만',
    next: 'final',
  },
]
export const ceoMonthlyIncomeOptionList = [
  {
    label: '월 소득 300만원 이상',
    value: '월 소득 300만원 이상',
    next: 'way',
  },
  {
    label: '월 소득 300만원 미만',
    value: '월 소득 300만원 미만',
    next: 'final',
  },
]
export const saleOptionList = [
  {
    label: '연 매출 4억 이상',
    value: '연 매출 4억 이상',
    next: 'way',
  },
  {
    label: '연 매출 4억 이하',
    value: '연 매출 4억 이하',
    next: 'monthlyIncome',
  },
]
export const monthlyIncomeOptionList = [
  {
    label: '월 소득 300만원 이상',
    value: '월 소득 300만원 이상',
    next: 'way',
  },
  {
    label: '월 소득 300만원 미만',
    value: '월 소득 300만원 미만',
    next: 'final',
  },
]
export const WayOptionList = [
  {
    label: '포탈 검색[ 구글 , 네이버 ]',
    boldLabel: '포탈 검색',
    desc: '[ 구글 , 네이버 ]',
    value: '포탈 검색',
  },
  {
    label: '온라인 광고[ 유튜브 , 인스타그램  ]',
    boldLabel: '온라인 광고',
    desc: '[ 유튜브 , 인스타그램  ]',
    value: '온라인 광고',
  },
  {
    label: '외부 플랫폼[ 부트텐트 , 인프런 등 ]',
    boldLabel: '외부 플랫폼',
    desc: '[ 부트텐트 , 인프런 등 ]',
    value: '외부 플랫폼',
  },
  {
    label: '네이버 블로그',
    value: '네이버 블로그',
  },
  {
    label: '문자 메시지 / 카카오 알림',
    value: '문자 메시지 / 카카오 알림',
  },
]

export const FinalOptionList = [
  {
    label: '해당 없음',
    value: '해당 없음',
  },
  {
    label: '생계급여 수급중인 사람 \n[ 조건부 수급자 또는 조건부과 유예자 제외 ]',
    boldLabel: '생계급여 수급중인 사람',
    desc: '[ 조건부 수급자 또는 조건부과 유예자 제외 ]',
    value: '생계급여 수급중인 사람',
  },
  {
    label: '타 부처 또는 지방자치단체로부터 교육/훈련비를 지원받고 있는 사람',
    value: '타 부처 또는 지방자치단체로부터 교육/훈련비를 지원받고 있는 사람',
  },
  {
    label: '지원/융자/수강제한 기간인 부정수급자',
    value: '지원/융자/수강제한 기간인 부정수급자',
  },
  {
    label: '부정행위에 따른 지원금을 반환하지 않은 사람',
    value: '부정행위에 따른 지원금을 반환하지 않은 사람',
  },
]
