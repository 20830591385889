'use client'

import {
  CompanyScaleOptionList,
  largeCompanyAgeOptionList,
  monthlyIncomeOptionList,
  saleOptionList,
  businessPeriodOptionList,
  GradeOptionList,
  SchoolOptionList,
  univGradeOptionList,
  ceoMonthlyIncomeOptionList,
  freeMonthlyIncomeOptionList,
  fourYearUnivOptionList,
} from '@/constatns/cardTest/cardTest'
import AgeForm from 'app/card-test/components/steps/age'
import CommonForm from 'app/card-test/components/steps/common'
import FinalForm from 'app/card-test/components/steps/final'
import TestIntroPage from 'app/card-test/components/steps/intro'
import JobForm from 'app/card-test/components/steps/job'
import WayForm from 'app/card-test/components/steps/way'
import { ReactNode, createContext, useState } from 'react'

export interface ITestDataType {
  [key: string]: string | string[]
}

interface ITestContextType {
  step: string
  setStep: React.Dispatch<React.SetStateAction<string>>
  steps: Record<string, ReactNode>
  data: ITestDataType | null
  setData: React.Dispatch<React.SetStateAction<ITestDataType | null>>
}
export interface ITestProviderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
}
export const TestContext = createContext<ITestContextType>({
  step: 'intro',
  setStep: () => {},
  steps: {},
  data: null,
  setData: () => {},
})

export const TestProvider = ({ children }: ITestProviderProps) => {
  const [step, setStep] = useState('intro')

  const steps = {
    intro: <TestIntroPage />,
    age: <AgeForm />,
    job: <JobForm />,
    grade: (
      <CommonForm
        options={GradeOptionList}
        keyValue="grade"
        title="현재 학년을 선택해주세요."
        percent={50}
      />
    ),
    school: (
      <CommonForm
        options={SchoolOptionList}
        keyValue="school"
        title="학교는 몇년제인지 선택해주세요."
        percent={50}
      />
    ),
    univGrade: (
      <CommonForm
        options={univGradeOptionList}
        keyValue="univGrade"
        title="현재 나의 학년을 선택해주세요."
        percent={66}
      />
    ),
    fourYearUnivGrade: (
      <CommonForm
        options={fourYearUnivOptionList}
        keyValue="fourYearUnivGrade"
        title="현재 나의 학년을 선택해주세요."
        percent={66}
      />
    ),
    companyScale: (
      <CommonForm
        options={CompanyScaleOptionList}
        keyValue="companyScale"
        title="회사의 규모를선택해주세요."
        percent={50}
      />
    ),
    businessPeriod: (
      <CommonForm
        options={businessPeriodOptionList}
        keyValue="businessPeriod"
        title="현재까지의 사업 기간을 선택해주세요."
        percent={50}
      />
    ),
    freeMonthlyIncome: (
      <CommonForm
        options={freeMonthlyIncomeOptionList}
        keyValue="freeMonthlyIncome"
        title="월 소득액을 선택해주세요."
        percent={50}
      />
    ),
    ceoMonthlyIncome: (
      <CommonForm
        options={ceoMonthlyIncomeOptionList}
        keyValue="ceoMonthlyIncome"
        title="월 소득액을 선택해주세요."
        percent={50}
      />
    ),
    largeCompanyAge: (
      <CommonForm
        options={largeCompanyAgeOptionList}
        keyValue="largeCompanyAge"
        title="현재 나의 나이를 선택해주세요."
        percent={66}
      />
    ),
    sales: (
      <CommonForm
        options={saleOptionList}
        keyValue="sales"
        title="현재까지 월 매출액을 선택해주세요."
        percent={66}
      />
    ),
    monthlyIncome: (
      <CommonForm
        options={monthlyIncomeOptionList}
        keyValue="monthlyIncome"
        title="현재 나의 월 소득액을 선택해주세요."
        percent={83}
      />
    ),
    way: <WayForm />,
    final: <FinalForm />,
  }

  const [data, setData] = useState<ITestDataType | null>(null)
  return (
    <TestContext.Provider value={{ step, setStep, data, setData, steps }}>
      {children}
    </TestContext.Provider>
  )
}
