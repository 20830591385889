import { JobOptionList } from '@/constatns/cardTest/cardTest'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import Image from 'next/image'
import { useContext, useState } from 'react'
import { TestContext } from 'shared/contexts/TestContext'

interface IAnswerType {
  label: string
  value: string
  next: string
}

const JobForm = () => {
  const { data, setData, setStep } = useContext(TestContext)
  const [answer, setAnswer] = useState<IAnswerType | null>(null)

  const onClickNextBtn = () => {
    if (answer) {
      setData({
        ...data,
        job: answer.value,
      })
      setStep(answer.next)
    }
  }
  return (
    <div className="flex h-full flex-col justify-between pt-10 lg:min-h-[990px]">
      <div>
        <div className="relative h-2.5 w-full rounded-full bg-[#F4F7F9]">
          <div className="absolute h-full w-2/6 rounded-full bg-[#FFDDC2]">
            <Image
              src="/svg/test_process_card.svg"
              alt="내일배움카드"
              width={40}
              height={28}
              className="absolute -right-1.5 -top-4"
            />
          </div>
        </div>
        <section className="mb-auto mt-9 h-full">
          <span className="text-sm font-medium text-yellow-450">내배카 대상 확인 테스트</span>
          <div className="mt-2">
            <p className="text-2xl font-semibold">현재 나의 상태를 선택해주세요.</p>
            <ul className="mt-6 flex flex-col gap-3">
              {JobOptionList.map(option => (
                <li key={option.value}>
                  <label
                    htmlFor={option.value}
                    className={`flex w-full cursor-pointer items-center justify-center rounded-xl py-3 ${answer?.value === option.value ? 'bg-yellow-450 font-semibold text-white shadow-[2px_4px_6px_0_rgba(255,217,188,0)]' : 'bg-[#F4F7F9]'}`}
                  >
                    <input
                      type="radio"
                      id={option.value}
                      onChange={() => setAnswer({ ...option })}
                      value={option.value}
                      checked={answer?.value === option.value}
                      className="hidden"
                    />
                    {option.label}
                  </label>
                </li>
              ))}
            </ul>
            <div className="mb-[60px] mt-4">
              <div className="flex items-center gap-2">
                <InformationCircleIcon className="w-5 text-gray-300" />
                <p className="text-sm font-semibold text-gray-500">특수고용형태종사자란?</p>
              </div>
              <div className="mt-2.5 flex flex-col gap-2.5">
                <p className="text-sm leading-[1.57] text-gray-500">
                  계약 형태와 관계없이 노무를 제공하여 근로하고 있는 경우를 말해요!
                </p>
                <p className="text-sm leading-[1.57] text-gray-500">
                  포함 업종 : 학습지교사, 학원 강사, 방문판매원, 보험설계사, 북큐레이터, 번역가,
                  택배기사, 골프장 캐디, 퀵서비스 기사, 대리운전기사, 건설기계를 직접 운전하는 사람
                  등
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="sticky bottom-0 left-0 w-full bg-white py-4">
        <button
          className={`w-full rounded-xl py-3 ${answer === null ? 'cursor-not-allowed border text-gray-300' : 'bg-[#262626] text-white'}`}
          disabled={answer === null}
          onClick={onClickNextBtn}
        >
          다음
        </button>
      </div>
    </div>
  )
}

export default JobForm
