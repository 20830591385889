import { transform, isString, isArray, isObject, camelCase, snakeCase, forEach } from 'lodash'

export enum ConvertType {
  'CAMEL',
  'SNAKE',
}

export const camelize = (obj: any) =>
  transform(obj, (acc: any, value, key, target) => {
    const camelKey = isArray(target) ? key : isString(key) ? camelCase(key) : key

    acc[camelKey] = isObject(value) ? camelize(value) : value
  })

export const snakelize = (obj: any) =>
  transform(obj, (acc: any, value, key, target) => {
    const snakeKey = isArray(target) ? key : isString(key) ? snakeCase(key) : key

    acc[snakeKey] = isObject(value) ? snakelize(value) : value
  })

export const convertCaseList = (list: any, resultType: ConvertType) =>
  resultType === ConvertType.CAMEL ? camelize(list) : snakelize(list)

export const convertCaseObject = (obj: any, convertType: ConvertType) => {
  const result = { ...obj }
  forEach(result, (value, key) => {
    if (isArray(value)) {
      result[key] = value.map(item =>
        convertType === ConvertType.CAMEL ? camelize(item) : snakelize(item),
      )
    } else if (isObject(value)) {
      result[key] = convertCaseObject(value, convertType)
    } else {
      result[key] = convertType === ConvertType.CAMEL ? camelize(value) : snakelize(value)
    }
  })

  return result
}
