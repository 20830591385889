import Image from 'next/image'
import { useContext, useState } from 'react'
import { TestContext } from 'shared/contexts/TestContext'

interface IAnswerType {
  label: string
  value: string
  next: string
}

interface ICommonFormProps {
  options: IAnswerType[]
  keyValue: string
  percent: number
  title: string
}
const CommonForm = ({ options, keyValue, percent, title }: ICommonFormProps) => {
  const { data, setData, setStep } = useContext(TestContext)
  const [answer, setAnswer] = useState<IAnswerType | null>(null)

  const disabled = answer === null

  const onClickHandler = () => {
    if (answer) {
      setData({
        ...data,
        [keyValue]: answer.value,
      })
      setStep(answer.next)
    }
  }
  return (
    <div className="flex h-full min-h-[calc(100vh-64px)] flex-col justify-between pt-10 lg:min-h-[990px]">
      <div>
        <div className="relative h-2.5 w-full rounded-full bg-[#F4F7F9]">
          <div
            className="absolute h-full rounded-full bg-[#FFDDC2]"
            style={{ width: `${percent}%` }}
          >
            <Image
              src="/svg/test_process_card.svg"
              alt="내일배움카드"
              width={40}
              height={28}
              className="absolute -right-1.5 -top-4"
            />
          </div>
        </div>
        <section className="mb-auto mt-9 h-full">
          <span className="text-sm font-medium text-yellow-450">내배카 대상 확인 테스트</span>
          <div className="mt-2">
            <p className="text-2xl font-semibold">{title}</p>
            <ul className="mt-6 flex flex-col gap-3">
              {options.map(option => (
                <li key={option.value}>
                  <label
                    htmlFor={option.value}
                    className={`flex w-full cursor-pointer items-center justify-center rounded-xl py-3 ${answer?.value === option.value ? 'bg-yellow-450 font-semibold text-white shadow-[2px_4px_6px_0_rgba(255,217,188,0)]' : 'bg-[#F4F7F9]'}`}
                  >
                    <input
                      type="radio"
                      id={option.value}
                      onChange={() => setAnswer({ ...option })}
                      value={option.value}
                      checked={answer?.value === option.value}
                      className="hidden"
                    />
                    {option.label}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </section>
      </div>
      <div className="sticky bottom-0 left-0 w-full pb-4">
        <button
          className={`w-full rounded-xl py-3 ${disabled ? 'cursor-not-allowed border text-gray-300' : 'bg-[#262626] text-white'}`}
          disabled={disabled}
          onClick={onClickHandler}
        >
          다음
        </button>
      </div>
    </div>
  )
}

export default CommonForm
