'use client'

import { WayOptionList } from '@/constatns/cardTest/cardTest'
import useNebecaTestMutation from 'app/card-test/queries/useNebecaTestMutation'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { ChangeEvent, useContext, useState } from 'react'
import { TestContext } from 'shared/contexts/TestContext'

const WayForm = () => {
  const [answer, setAnswer] = useState(new Set())
  const router = useRouter()
  const { data, setData } = useContext(TestContext)
  const postTestMutate = useNebecaTestMutation()

  const onClickNextBtn = () => {
    setData({
      ...data,
      way: Array.from(answer) as string[],
    })
    const serverData = {
      sentInfo: { ...data, way: Array.from(answer) as string[] },
    }
    postTestMutate.mutate(serverData)
    return router.push('/card-test/complete')
  }

  const onStudyStyleHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const copyStudyStyle = new Set(answer)
    const button = e.target.value
    if (copyStudyStyle.has(button)) {
      copyStudyStyle.delete(button)
    } else {
      copyStudyStyle.add(button)
    }
    setAnswer(copyStudyStyle)
  }

  return (
    <div className="flex h-full min-h-[calc(100vh-64px)] flex-col justify-between pt-10 lg:min-h-[990px]">
      <div>
        <div className="relative h-2.5 w-full rounded-full bg-[#F4F7F9]">
          <div className="absolute h-full w-full rounded-full bg-[#FFDDC2]">
            <Image
              src="/svg/test_process_card.svg"
              alt="내일배움카드"
              width={40}
              height={28}
              className="absolute -right-1.5 -top-4"
            />
          </div>
        </div>
        <section className="mb-auto mt-9 h-full">
          <span className="text-sm font-medium text-yellow-450">내배카 대상 확인 테스트</span>
          <div className="mt-2">
            <p className="text-2xl font-semibold">부트캠프를 알게된 경로</p>
            <ul className="mt-6 flex flex-col">
              {WayOptionList.map(option => (
                <li key={option.value} className="py-3">
                  <label
                    htmlFor={option.value}
                    className="flex w-full cursor-pointer items-center justify-between"
                  >
                    {option.boldLabel ? (
                      <div className="flex flex-col">
                        <span className={answer.has(option.value) ? 'font-semibold' : ''}>
                          {option.boldLabel}
                        </span>
                        <p className="text-gray-400">{option.desc}</p>
                      </div>
                    ) : (
                      <span className={answer.has(option.value) ? 'font-semibold' : ''}>
                        {option.label}
                      </span>
                    )}
                    <div className="relative inline-flex size-5 items-center justify-center">
                      <input
                        type="checkbox"
                        id={option.value}
                        onChange={e => onStudyStyleHandler(e)}
                        value={option.value}
                        checked={answer.has(option.value)}
                        className="peer hidden appearance-none"
                      />
                      <div className="absolute left-0 top-0 size-6">
                        {answer.has(option.value) ? (
                          <div className="absolute left-0 top-0 h-full w-full">
                            <img src="/svg/active_checkbox.svg" alt="checkbox" />
                          </div>
                        ) : (
                          <div className="absolute left-0 top-0 h-full w-full">
                            <img src="/svg/inactive_checkbox.svg" alt="checkbox" />
                          </div>
                        )}
                      </div>
                    </div>
                  </label>
                </li>
              ))}
            </ul>
            <p className="mb-[60px] text-sm text-[#FF6352]"> * 중복 선택이 가능해요!</p>
          </div>
        </section>
      </div>
      <div className="sticky bottom-0 left-0 w-full bg-white pb-4">
        <button
          className={`flex w-full items-center justify-center rounded-xl py-3 ${answer.size === 0 ? 'cursor-not-allowed border text-gray-300' : 'bg-[#262626] text-white'}`}
          disabled={answer.size === 0}
          onClick={onClickNextBtn}
        >
          결과 보러가기
          {answer.size !== 0 && (
            <div className="ml-1.5 w-7">
              <img src="/svg/nebeca_card.svg" alt="결과 보러가기" />
            </div>
          )}
        </button>
      </div>
    </div>
  )
}

export default WayForm
