import Lottie from 'react-lottie'
import animationData from '@/public/lottie/test_intro.json'

const IntroLottie = () => {
  return (
    <div className="">
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData,
        }}
      />
    </div>
  )
}

export default IntroLottie
