import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import Image from 'next/image'
import { useContext, useState } from 'react'
import { TestContext } from 'shared/contexts/TestContext'
import IntroLottie from '../lottie/IntroLottie'
import Link from 'next/link'

const TestIntroPage = () => {
  const { setStep } = useContext(TestContext)
  const [isOpen, setIsOpen] = useState(false)
  const onClickHandler = () => {
    setIsOpen(!isOpen)
  }
  const onClickNextStep = () => {
    setStep('age')
  }
  return (
    <main className="flex items-center justify-center">
      <div className="w-full">
        <div className="mt-10">
          <div className="flex flex-col gap-4">
            <p className="text-center text-yellow-450">국민내일배움카드 대상자 확인 테스트</p>
            <div className="mx-auto flex items-center justify-center">
              <Image
                src={'/img/nebeca_intro.png'}
                alt="혹시 나도 내배카 대상자?"
                width={255}
                height={110}
              />
            </div>
            <p className="text-center text-sm text-gray-400">
              국민내일배움카드 대상자라면
              <br />
              국비지원교육을 무료로 받을 수 있어요!
            </p>
          </div>
          <div className="mx-auto mt-6 h-[180px] w-[180px]">
            <IntroLottie />
          </div>
          <footer className="sticky bottom-0 left-0">
            <div
              className="mt-[88px] cursor-pointer rounded-xl bg-[#F4F7F9] p-3"
              onClick={onClickHandler}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <InformationCircleIcon className="w-5 text-gray-300" />
                  <p className="text-sm font-semibold text-gray-500">국민내일배움카드란?</p>
                </div>
                <ChevronDownIcon
                  className={`${isOpen && 'rotate-180 transform'} w-5 text-gray-300`}
                />
              </div>
              {isOpen && (
                <div className="mt-2.5">
                  <p className="text-sm leading-[1.57] text-gray-500">
                    국민내일배움카드 발급 시, 국비지원으로 최대 500만원까지 교육과정을 수강할 수
                    있습니다.
                  </p>
                </div>
              )}
            </div>
            <div className="mt-4 pb-4">
              <button
                className="inline-flex w-full items-center justify-center rounded-lg bg-yellow-450 px-6 py-2.5"
                onClick={onClickNextStep}
              >
                <span className="font-semibold text-white">10초 만에 내일배움카드 확인하기</span>
              </button>
            </div>
          </footer>
        </div>
      </div>
    </main>
  )
}
export default TestIntroPage
